const { createApp } = Vue;
const app = createApp({
  data() {
    return {
      pageTab: sessionStorage.hash || '#',
      previewTab1: 'app',
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      isPlay: false,
    }
  },
  methods: {
    registerHandle() {
      window.open('https://22tj.com', '_blank')
    },
    pageHandle(v) {
      clearTimeout(this.pageTimer)
      window.removeEventListener('scroll', this.scrollHandle)
      location.hash = v.paneName;
      sessionStorage.hash = v.paneName;
      this.pageTimer = setTimeout(() => {
        window.addEventListener('scroll', this.scrollHandle)
        clearTimeout(this.pageTimer)
      }, 600)
    },
    addCount(key, max, string) {
      return new Promise((resolve) => {
        this[key + 'timer'] = setInterval(() => {
          this[key] += Math.ceil(max / (1000 / 17))
          if (this[key] >= max) {
            this[key] = max + string
            clearInterval(this[key + 'timer'])
            resolve(true)
          }
        }, 17)
      })
    },
    updateCount() {
      if (this.isPlay) return;
      this.isPlay = true;
      Promise.all([
        this.addCount('count1', 5000, '+'),
        this.addCount('count2', 10, '万+'),
        this.addCount('count3', 500, '+'),
        this.addCount('count4', 1000, '+')
      ]).then(() => {
        this.isPlay = false;
      })
    },
    closeUpdate() {
      clearInterval(this.count1timer)
      clearInterval(this.count2timer)
      clearInterval(this.count3timer)
      clearInterval(this.count4timer)
      this.isPlay = false
      this.count1 = 0
      this.count2 = 0
      this.count3 = 0
      this.count4 = 0
    },
    scrollHandle() {
      const arr = [...this.$refs.main.querySelectorAll('section')].map(v => {
        return Math.abs(v.getBoundingClientRect().top)
      })
      const v = Math.min(...arr)
      const i = arr.findIndex((vv) => vv === v)
      const hash = '#' + this.$refs.main.querySelectorAll('section')[i].id
      this.pageTab = hash
      // location.hash = hash
      sessionStorage.hash = hash
    }
  },
  mounted() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.updateCount()
        } else {
          this.closeUpdate()
        }
      });
    }, {
      root: null,
      threshold: 0.1
    });
    observer.observe(this.$refs.dataList);
    window.addEventListener('scroll', this.scrollHandle)
  }
})
app.use(ElementPlus)
app.mount('#app');
